.header-navigation-list {
  display: flex;
}
.header-navigation-list li {
  display: flex;
}
.header-navigation-item a {
  padding: 6px 12px;
  color: var(--gray-700);
  font-size: 14px;
  font-weight: 500;
}
.header-navigation-list.header-underlined a {
  border-bottom: 1px solid var(--gray-100);
}
.header-navigation-item a.active,
.header-navigation-item.header-underlined a.active {
  border-bottom: 2px solid var(--brand-600);
  color: var(--brand-800);
}
